import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";
import { Link } from "gatsby";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";

const GalleryItemComponent = (data) => {
  // const imageAlbumComponent = () => {
  //   <div class="grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
  //     <div class="relative">
  //       <div class="featured-imagebox featured-imagebox-portfolio">
  //         <div class="featured-thumbnail">
  //           <a href="#">
  //             <img
  //               class="img-fluid"
  //               src="	https://eventplannerstaging.netlify.app/images/gallery/gallery-1.jpg"
  //               alt="image"
  //             />
  //           </a>
  //         </div>

  //         <div class="ttm-box-view-overlay">
  //           <div class="ttm-media-link">
  //             <a
  //               class="ttm_prettyphoto ttm_image"
  //               data-gal="prettyPhoto[gallery1]"
  //               title="Autue Art Gallery Opening"
  //               href="	https://eventplannerstaging.netlify.app/images/gallery/gallery-1.jpg"
  //               data-rel="prettyPhoto"
  //             >
  //               <i class="ti ti-search">
  //                 <FontAwesomeIcon
  //                   icon={faSearch}
  //                   className="text-white"
  //                   size="lg"
  //                 />
  //               </i>
  //             </a>
  //           </div>
  //           <div class="featured-content featured-content-portfolio">
  //             <div class="featured-title">
  //               <h5>
  //                 <a href="portfolio-details-02.html">
  //                   Autue Art Gallery Opening
  //                 </a>
  //               </h5>
  //             </div>
  //             <span class="category">
  //               <a href="private-party.html">Private Party</a>
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>;
  // };

  return (
    <>
      <div className="mx-auto max-w-6xl z-10">
        <div className="lg:px-16 px-6 pb-10">
          {/* <div className="text-center text-4xl font-bold py-20">
            <h1>Our Gallery</h1>
          </div> */}
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-10 lg:py-20">
            {data.data.data.page.edges.map((currEle, idx) => {
              // console.log(currEle, "currEle");
              return (
                <div className="relative" key={idx}>
                  <div className="overflow-hidden">
                    <GatsbyImageCustom
                      id={currEle.node.cover_image.asset._id}
                      alt="img"
                      className="h-96 w-full rounded filter brightness-50 hover:brightness-75 transition-all transform ease-in-out hover:scale-110 cursor-pointer object-cover"
                    />
                  </div>
                  <div className="text-white absolute top-36 w-full text-center ">
                    <h1 className=" text-center tracking-wider font-bold text-2xl">
                      {currEle.node.title}
                    </h1>
                    <div className="pt-5">
                      <Link to={currEle.node.slug.current}>
                        <button className="uppercase p-2 border font-bold border-white w-40 hover:bg-white hover:text-black transition duration-500 tracking-widest text-xs ">
                          View photos
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div class="relative">
              <img
                src="https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg"
                alt="Avatar"
                class="block w-full h-auto"
              />
              <div class="absolute inset-0  h-full w-full  opacity-0 transition duration-300 ease-in-out bg-gray-500 hover:opacity-100">
                <div class="text-white absolute  text-xl top-1/2 left-1/2 text-center">
                  Hello World
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default GalleryItemComponent;
