import React from "react";
import Errors from "../Components/Errors/Errors";
 import GalleryHeaderSection from "../Components/Gallery/GalleryHeaderSection";
 import GalleryItemComponent from "../Components/Gallery/GalleryItemComponent";
 import Layout from "../Components/Layout/Layout";
 import { graphql } from "gatsby";
 
export const query = graphql`
  query GalleryPageQuery {
    page: allSanityGallerypage {
      edges {
        node {
          title
          cover_image {
            asset {
              _id
            }
          }
          gallery_images {
            asset {
              _id
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

const GalleryPage = (props) => {
  // console.log(props, "gallerypage");

  const {  errors } = props;
  if (errors) {
    return <Errors errors={errors} />;
  }
  return (
    <>
      <Layout location={props.location}>
        <GalleryHeaderSection />
        <GalleryItemComponent data={props} />
      </Layout>
    </>
  );
};


export default GalleryPage;
