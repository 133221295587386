import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
// import GalleyHeader from "../../assets/images/gallerHeader.png";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const GalleryHeaderSection = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { eq: "gallerHeader.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
      }
    }
  `);
  // console.log(images,'sad')
  return (
    <>
      <div className="relative z-10">
        <GatsbyImage
          image={getImage(images.allFile.nodes[0].childImageSharp)}
          alt="image"
          className="w-full filter brightness-75 object-cover"
          style={{ height: "500px" }}
        />
        {/* <GatsbyImageCustom
          style={{ height: "500px" }}
          id={ImageData[0].node.id}
          alt="image header"
          className=" w-full filter brightness-75 object-cover"
        /> */}
        {/* <img
          style={{ height: "500px" }}
          src={GalleyHeader}
          alt="header"
          className=" w-full filter brightness-75 object-cover"
        /> */}
        <div className="mx-auto max-w-6xl">
          <div className=" absolute left-0 right-0 top-0 bottom-0 m-auto flex justify-center items-center">
            <div className="px-4">
              <div className="font-bold lg:text-6xl md:text-4xl  text-2xl text-white text-center">
                Gallery
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryHeaderSection;
